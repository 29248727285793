import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    this.activeLinkClasses = (this.data.get('activeLink') || 'active').split(' ')
    this.inactiveLinkClasses = (this.data.get('inactiveLink') || 'inactive').split(' ')
    this.currentHash = window.location.hash.substr(1)
    if (this.currentHash) {
      this.currentLink = this.element.querySelector(`[href$="#${this.currentHash}"`)

      if (this.currentLink) {
        this.linkTargets.forEach(link => {
          DOMTokenList.prototype.remove.apply(link.classList, this.activeLinkClasses)
        })
        DOMTokenList.prototype.remove.apply(this.currentLink.classList, this.inactiveLinkClasses)
        DOMTokenList.prototype.add.apply(this.currentLink.classList, this.activeLinkClasses)
      }
    }
  }

  toggle(event) {
    this.linkTargets.forEach(link => {
      DOMTokenList.prototype.remove.apply(link.classList, this.activeLinkClasses)
    })
    DOMTokenList.prototype.remove.apply(event.currentTarget.classList, this.inactiveLinkClasses)
    DOMTokenList.prototype.add.apply(event.currentTarget.classList, this.activeLinkClasses)
  }
}
