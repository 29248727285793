import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    datetime: String,
    refreshInterval: Number
  }

  initialize () {
    this.isValid = true
  }

  connect () {
    this.element[this.identifier] = this

    this.load()

    if (this.hasRefreshIntervalValue && this.isValid) {
      this.startRefreshing()
    }
  }

  disconnect () {
    this.stopRefreshing()
  }

  load () {
    const datetime = this.datetimeValue
    const date = Date.parse(datetime)

    if (Number.isNaN(date)) {
      this.isValid = false

      console.error(
        `[stimulus-timeago] Value given in 'data-timeago-datetime' is not a valid date (${datetime}). Please provide a ISO 8601 compatible datetime string. Displaying given value instead.`
      )
    }

    this.element.dateTime = datetime
    this.element.innerHTML = this.isValid ? this.time_between(date, Date.now()) : datetime
  }

  startRefreshing () {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing () {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  time_between(start_time, end_time) {
    if (start_time.nil || end_time.nil) return;
    const one_unit_as_seconds = {
      "weeks": 604800,
      "days": 86400,
      "hours": 3600,
      "minutes": 60
    }
    const time_diff_in_milliseconds = Math.abs(end_time - start_time)
    const time_diff_in_seconds = Math.round(time_diff_in_milliseconds / 1000)

    const round_to_two_decimals = function(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100
    }
    const stored_seconds_reducer = function (total, pair) {
      const [component, value] = pair
      const one_component_as_seconds = one_unit_as_seconds[component]
      const component_total_as_seconds = one_component_as_seconds * value
      return total + component_total_as_seconds
    }
    const diff_parts_hash_reducer = function (memo, time_component) {
      const stored_seconds = Object.entries(memo).reduce(stored_seconds_reducer, 0)
      const stored_diff_in_component = ((time_diff_in_seconds - stored_seconds) / one_unit_as_seconds[time_component])
      const diff_part = Math.floor(round_to_two_decimals(stored_diff_in_component))

      if (diff_part >= 1) {
        return {
          ...memo,
          [time_component]: diff_part
        }
      } else {
        return memo
      }
    }

    // {weeks: 0, days: 0, hours: 0, minutes: 2}
    const diff_parts_hash = ["weeks", "days", "hours", "minutes"].reduce(diff_parts_hash_reducer, {})
    const diff_parts_as_seconds = Object.entries(diff_parts_hash).reduce(stored_seconds_reducer, 0)
    const diff_seconds_left = time_diff_in_seconds - diff_parts_as_seconds
    if (diff_seconds_left >= 1) {
      diff_parts_hash["seconds"] = diff_seconds_left
    }

    const diff_parts_str_map = function (part) {
      const [component, value] = part
      const unit = component[0] // first letter of the string

      return [value, unit].join('')
    }

    const diff_parts_str = Object.entries(diff_parts_hash).map(diff_parts_str_map)

    return diff_parts_str.join(' ')
  }
}
