import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dynamic" ]

  toggle(event) {
    if (event.currentTarget.checked) {
      this.dynamicTarget.classList.remove('line-through')
    } else {
      this.dynamicTarget.classList.add('line-through')
    }
  }
}
