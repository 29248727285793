import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggleable", "openIcon", "closeIcon" ]

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    event.preventDefault()

    this.toggleableTargets.forEach( target => {
      target.classList.toggle(this.toggleClass)
    })
    this.openIconTarget.classList.toggle(this.toggleClass)
    this.closeIconTarget.classList.toggle(this.toggleClass)
  }
}
