import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "show", "hide" ]

  connect() {
    this.toggleClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    if (this.needToHideInputContents) {
      this.inputTarget.type = "text";
    } else {
      this.inputTarget.type = "password";
    }

    this.showTarget.classList.toggle(this.toggleClass)
    this.hideTarget.classList.toggle(this.toggleClass)
  }

  get needToHideInputContents() {
    return this.hideTarget.classList.contains('hidden')
  }
}
