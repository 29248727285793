import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "container", "queuedList", "failedList", "succeededList", "successStatus", "failureStatus", "editor", "error", "report", "reportContent", "reportTitle", "fileInput", "reportImages", "template", "feedbackSuccessTimeago", "reviewSuccessTimeago", "reviewFailureTimeago", "feedbackFailureTimeago", "reportVerb" ]
  }

  connect() {
    this.errorTargets.forEach(el => el.setAttribute('hidden', true))
  }

  setDefaultSuccessText() {
    this.element.querySelector('trix-editor').innerHTML = '<div><!--block-->Everything looks <strong><em>good</em></strong>! I walked through the entire feature and everything was as described in the ticket. This is ready to be deployed IMO</div>'
  }

  setDefaultFailureText() {
    this.element.querySelector('trix-editor').innerHTML = '<div><!--block-->I only found 1 bug.<br><strong><h2>Steps to reproduce</h2></strong></div><ol><li><!--block-->login as the supplied customer user</li><li><!--block-->navigate to the new Products Highlight page</li><li><!--block-->add any product to the Cart</li><li><!--block-->increase the quantity of the product</li></ol><div><!--block--><strong><h2>Expected result</h2></strong>The <ins>Subtotal</ins> is updated to match the value of the product multiplied by the quantity.</div><div><!--block--><strong><h2>Actual result</h2></strong>The <ins>Subtotal</ins> is not updated at all</div>'
  }

  toggleGitHubChecks() {
    event.preventDefault()

    if (this.successStatusTarget.checked) {
      this.errorTargets.forEach(el => el.setAttribute('hidden', true))
      this.feedbackSuccessTimeagoTarget.timeago.load()
      this.reviewSuccessTimeagoTarget.timeago.load()
      this.reportVerbTarget.innerText = "Succeeded"
      this.showSucceeded()
    } else if (this.failureStatusTarget.checked) {
      this.errorTargets.forEach(el => el.setAttribute('hidden', true))
      this.feedbackFailureTimeagoTarget.timeago.load()
      this.reviewFailureTimeagoTarget.timeago.load()
      this.reportVerbTarget.innerText = "Failed"
      this.showFailed()
    } else {
      this.errorTargets.forEach(el => el.removeAttribute('hidden'))
      this.reportVerbTarget.innerText = "Started"
      this.showQueued()
    }

    this.reportContentTarget.innerHTML = this.editorTarget.querySelector('trix-editor').innerHTML

    if (this.fileInputTarget.files) {
      this.reportImagesTarget.querySelectorAll('figure').forEach(el => el.remove())
      this.fileInputTarget.files.forEach(file => {
        this.addFile(this.reportImagesTarget, file)
      })
    }
  }

  showQueued() {
    this.queuedListTarget.classList.remove("hidden")
    this.failedListTarget.classList.add("hidden")
    this.succeededListTarget.classList.add("hidden")

    this.containerTarget.classList.remove("branch-action-state-clean")
    this.containerTarget.classList.add("branch-action-state-unstable")
  }

  showFailed() {
    this.queuedListTarget.classList.add("hidden")
    this.failedListTarget.classList.remove("hidden")
    this.succeededListTarget.classList.add("hidden")

    this.containerTarget.classList.remove("branch-action-state-clean")
    this.containerTarget.classList.add("branch-action-state-unstable")

    this.reportTitleTarget.innerText = "Changes are needed…"
  }

  showSucceeded() {
    this.queuedListTarget.classList.add("hidden")
    this.failedListTarget.classList.add("hidden")
    this.succeededListTarget.classList.remove("hidden")

    this.containerTarget.classList.add("branch-action-state-clean")
    this.containerTarget.classList.remove("branch-action-state-unstable")

    this.reportTitleTarget.innerText = "Everything looks good!"
  }

  addFile(target, file) {
    // const isImage = file.type.match("image.*")
    const objectURL = URL.createObjectURL(file)
    const clone = this.templateTarget.content.cloneNode(true)

    clone.id = objectURL

    Object.assign(clone.querySelector("img"), {
      src: objectURL,
      alt: file.name
    })

    target.append(clone)
  }
}
