import { Controller } from "stimulus"
import "src/number_helpers"

export default class extends Controller {
  static targets = [ "template", "gallery", "input", "preview", "attachedBulkAction", "section" ]

  connect() {}

  previewInGallery() {
    if (this.inputTarget.files) {
      this.sectionTarget.classList.remove('hidden')
      this.galleryTarget.querySelectorAll('li').forEach(n => n.remove())

      this.inputTarget.files.forEach(file => {
        this.addFile(this.galleryTarget, file)
      })
    }
  }

  toggleAttachedBulkActionText(event) {
    if (event.currentTarget.checked) {
      this.attachedBulkActionTarget.textContent = 'Detach all'
    } else {
      this.attachedBulkActionTarget.textContent = 'Re-attach all'
    }
  }

  removeAll() {
    var fileFieldHTML = this.inputTarget.outerHTML
    var parent = this.inputTarget.parentElement
    this.inputTarget.remove()
    parent.insertAdjacentHTML('afterbegin', fileFieldHTML)
    this.galleryTarget.querySelectorAll('li').forEach(n => n.remove())
    this.sectionTarget.classList.add('hidden')
  }

  addFile(target, file) {
    // const isImage = file.type.match("image.*")
    const objectURL = URL.createObjectURL(file)
    const clone = this.templateTarget.content.cloneNode(true)

    clone.querySelector("[data-target='name']").textContent = file.name
    clone.id = objectURL
    // clone.querySelector(".delete").dataset.target = objectURL
    clone.querySelector("[data-target='size']").textContent = '(' + NumberHelpers.number_to_human_size(file.size) + ')'

    Object.assign(clone.querySelector("img"), {
      src: objectURL,
      alt: file.name
    })

    target.append(clone)
  }
}
