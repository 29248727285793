import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "defaultInput", "defaultSHA", "defaultBranch", "repositoryDefaultBranchList" ]

  connect() {}
	
	updateDefaultHeadShaValue(evt) {
		const selectedRepositoryId = evt.currentTarget.value
		const selectedRepositoryDefaultBranchOption = this.repositoryDefaultBranchListTarget.querySelector(`[value="${selectedRepositoryId}"]`)
		const selectedRepositoryDefaultBranch = selectedRepositoryDefaultBranchOption.innerText
		
		this.defaultInputTarget.value = selectedRepositoryDefaultBranch + ":HEAD"
		this.defaultBranchTarget.innerText = selectedRepositoryDefaultBranch
	}
}
