NumberHelpers = (function() {
  function NumberHelpers() {}

  NumberHelpers.ZEROS = new Array(200).join('0');

  NumberHelpers.number_to_currency = function(float, opts) {
    var _delimiter, _precision, _separator, _unit, _unit_pos, delimited, fixedWidth, ref, ref1, ref2, ref3, ref4, result, sign;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 2;
    _unit = (ref1 = opts.unit) != null ? ref1 : '$';
    _separator = (ref2 = opts.separator) != null ? ref2 : '.';
    _delimiter = (ref3 = opts.delimiter) != null ? ref3 : ',';
    _unit_pos = (ref4 = opts.unit_position) != null ? ref4 : 'start';
    sign = '';
    if (isNaN(float)) {
      result = float;
    } else {
      float = parseFloat(float);
      if (float < 0) {
        sign = '-';
      }
      fixedWidth = Math.abs(float).toFixed(_precision);
      delimited = NumberHelpers.number_with_delimiter(fixedWidth, {
        delimiter: _delimiter
      });
      result = delimited.split('.').join(_separator);
    }
    if (_unit_pos === 'end') {
      return "" + sign + result + _unit;
    } else {
      return "" + sign + _unit + result;
    }
  };

  NumberHelpers.number_with_delimiter = function(float, opts) {
    var _delimiter, _separator, decimal, integer, number, ref, ref1, ref2, rgx;
    if (opts == null) {
      opts = {};
    }
    _separator = (ref = opts.separator) != null ? ref : '.';
    _delimiter = (ref1 = opts.delimiter) != null ? ref1 : ',';
    number = float.toString().split(".");
    integer = number[0];
    decimal = (ref2 = number[1]) != null ? ref2 : '';
    if (!decimal) {
      _separator = '';
    }
    rgx = /(\d+)(\d{3})/;
    if (_delimiter) {
      while (rgx.test(integer)) {
        integer = integer.replace(rgx, "$1" + _delimiter + "$2");
      }
    }
    return "" + integer + _separator + decimal;
  };

  NumberHelpers.safe_round = function(float, _significant, _precision) {
    var _round, value;
    if (!float) {
      return float;
    }
    value = (+float).toExponential().toString().split('e');
    _round = _significant ? -1 - value[1] + _precision : _precision;
    value = Math.round(+(value[0] + 'e' + (+value[1] + _round)));
    value = value.toExponential().toString().split('e');
    return value = +(value[0] + 'e' + (+value[1] - _round));
  };

  NumberHelpers.number_with_precision = function(float, opts) {
    var _delimiter, _precision, _separator, _significant, _skip_empty_fractionals, _strip_insignificant_zeros, decimal, dlen, i, integer, multiple, newlen, num_array, num_lngth, number, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, rnd, rounded, significant, sigs, zcount;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 3;
    _delimiter = (ref1 = opts.delimiter) != null ? ref1 : ',';
    _separator = (ref2 = opts.separator) != null ? ref2 : '.';
    _significant = (ref3 = opts.significant) != null ? ref3 : false;
    _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false;
    _skip_empty_fractionals = opts.strip_empty_fractional_parts;
    number = float.toString().split('.');
    integer = number[0];
    decimal = (ref5 = number[1]) != null ? ref5 : '';
    if (_significant) {
      rnd = _precision - integer.length;
    } else {
      rnd = _precision;
    }
    if (rnd < 1) {
      rnd = 0;
    }
    multiple = Math.pow(10, rnd);
    if (multiple > 1) {
      rounded = this.safe_round(float, _significant, _precision);
    } else {
      rounded = float;
    }
    number = rounded.toString().split('.');
    integer = number[0];
    decimal = (ref6 = number[1]) != null ? ref6 : '';
    decimal = parseFloat("0." + decimal).toFixed(_precision);
    decimal = decimal.toString().split('.');
    decimal = (ref7 = decimal[1]) != null ? ref7 : '';
    number = (integer + "." + decimal) * 1;
    num_array = number.toString().split('');
    num_lngth = num_array.length;
    i = 0;
    sigs = 0;
    while (i < num_lngth) {
      if (!(num_array[i] === '.' || num_array[i] === '0')) {
        sigs++;
      }
      i++;
    }
    if (_significant && sigs >= _precision && _precision > 0) {
      significant = number.toPrecision(_precision) * 1;
      significant = significant.toString().split('.');
      integer = significant[0];
      decimal = (ref8 = significant[1]) != null ? ref8 : '';
    }
    integer = NumberHelpers.number_with_delimiter(integer, {
      delimiter: _delimiter
    });
    if (_strip_insignificant_zeros) {
      dlen = decimal.length;
      newlen = dlen;
      while (newlen > 0 && decimal[newlen - 1] === '0') {
        newlen = newlen - 1;
      }
      if (newlen === 0) {
        decimal = '';
      } else if (newlen !== dlen) {
        decimal = decimal.slice(0, newlen);
      }
    }
    if (_skip_empty_fractionals) {
      i = 0;
      zcount = 0;
      num_array = decimal.split('');
      dlen = decimal.length;
      while (i < dlen) {
        if (num_array[i] === '0') {
          zcount++;
        }
        i++;
      }
      if (zcount === dlen) {
        decimal = '';
      }
    }
    if (!decimal) {
      _separator = '';
    }
    return "" + integer + _separator + decimal;
  };

  NumberHelpers.number_to_human = function(float, opts) {
    var _delimiter, _labels, _precision, _separator, _significant, _space_label, _strip_insignificant_zeros, abs_float, denom, label, number, precise, ref, ref1, ref10, ref11, ref12, ref13, ref14, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 3;
    _separator = (ref1 = opts.separator) != null ? ref1 : '.';
    _significant = (ref2 = opts.significant) != null ? ref2 : true;
    _delimiter = (ref3 = opts.delimiter) != null ? ref3 : ',';
    _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false;
    _space_label = opts.space_label === false ? '' : ' ';
    _labels = {
      thousand: (ref5 = (ref6 = opts.labels) != null ? ref6.thousand : void 0) != null ? ref5 : 'Thousand',
      million: (ref7 = (ref8 = opts.labels) != null ? ref8.million : void 0) != null ? ref7 : 'Million',
      billion: (ref9 = (ref10 = opts.labels) != null ? ref10.billion : void 0) != null ? ref9 : 'Billion',
      trillion: (ref11 = (ref12 = opts.labels) != null ? ref12.trillion : void 0) != null ? ref11 : 'Trillion',
      quadrillion: (ref13 = (ref14 = opts.labels) != null ? ref14.quadrillion : void 0) != null ? ref13 : 'Quadrillion'
    };
    abs_float = Math.abs(float);
    if (abs_float < Math.pow(10, 3)) {
      denom = 1;
      label = false;
    } else if (abs_float >= Math.pow(10, 3) && abs_float < Math.pow(10, 6)) {
      denom = Math.pow(10, 3);
      label = _labels.thousand;
    } else if (abs_float >= Math.pow(10, 6) && abs_float < Math.pow(10, 9)) {
      denom = Math.pow(10, 6);
      label = _labels.million;
    } else if (abs_float >= Math.pow(10, 9) && abs_float < Math.pow(10, 12)) {
      denom = Math.pow(10, 9);
      label = _labels.billion;
    } else if (abs_float >= Math.pow(10, 12) && abs_float < Math.pow(10, 15)) {
      denom = Math.pow(10, 12);
      label = _labels.trillion;
    } else if (abs_float >= Math.pow(10, 15)) {
      denom = Math.pow(10, 15);
      label = _labels.quadrillion;
    }
    number = float / denom;
    precise = NumberHelpers.number_with_precision(number, {
      precision: _precision,
      significant: _significant,
      delimiter: label === 'Quadrillion' ? '' : _delimiter,
      separator: _separator,
      strip_insignificant_zeros: !label ? true : _strip_insignificant_zeros
    });
    if (label) {
      return "" + precise + _space_label + label;
    } else {
      return precise;
    }
  };

  NumberHelpers.number_to_human_size = function(float, opts) {
    var _delimiter, _precision, _separator, _significant, _strip_insignificant_zeros, abs_float, denom, label, number, precise, ref, ref1, ref2, ref3, ref4;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 3;
    _separator = (ref1 = opts.separator) != null ? ref1 : '.';
    _significant = (ref2 = opts.significant) != null ? ref2 : true;
    _delimiter = (ref3 = opts.delimiter) != null ? ref3 : ',';
    _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false;
    if (float > 1000) {
      float = float / 1.024;
    }
    if (float > 1000000) {
      float = float / 1.024;
    }
    if (float > 1000000000) {
      float = float / 1.024;
    }
    if (float > 1000000000000) {
      float = float / 1.024;
    }
    abs_float = Math.abs(float);
    if (abs_float < Math.pow(10, 3)) {
      denom = 1;
      label = 'Bytes';
    } else if (abs_float >= Math.pow(10, 3) && abs_float < Math.pow(10, 6)) {
      denom = Math.pow(10, 3);
      label = 'KB';
    } else if (abs_float >= Math.pow(10, 6) && abs_float < Math.pow(10, 9)) {
      denom = Math.pow(10, 6);
      label = 'MB';
    } else if (abs_float >= Math.pow(10, 9) && abs_float < Math.pow(10, 12)) {
      denom = Math.pow(10, 9);
      label = 'GB';
    } else if (abs_float >= Math.pow(10, 12) && abs_float < Math.pow(10, 15)) {
      denom = Math.pow(10, 12);
      label = 'TB';
    }
    number = float / denom;
    precise = NumberHelpers.number_with_precision(number, {
      precision: _precision,
      significant: _significant,
      delimiter: _delimiter,
      separator: _separator,
      strip_insignificant_zeros: label === 'Bytes' ? true : _strip_insignificant_zeros
    });
    return precise + " " + label;
  };

  NumberHelpers.number_to_phone = function(number, opts) {
    var _area_code, _country_code, _delimiter, _extension, first, last, lng, ref, ref1, ref2, ref3, second, str;
    if (opts == null) {
      opts = {};
    }
    _area_code = (ref = opts.area_code) != null ? ref : false;
    _delimiter = (ref1 = opts.delimiter) != null ? ref1 : '-';
    _country_code = (ref2 = opts.country_code) != null ? ref2 : false;
    _extension = (ref3 = opts.extension) != null ? ref3 : false;
    if (isNaN(number)) {
      return number;
    }
    str = number.toString();
    lng = str.length;
    last = str.substr(lng - 4, lng);
    if (lng < 8) {
      first = str.substr(0, 3);
    } else {
      first = str.substr(0, 3);
      second = str.substr(3, 3);
      if (_area_code) {
        first = "(" + first + ") " + second;
      } else {
        first = "" + first + _delimiter + second;
      }
    }
    _extension = _extension ? " x " + opts.extension : '';
    _country_code = _country_code ? "+" + _country_code + _delimiter : '';
    return "" + _country_code + first + _delimiter + last + _extension;
  };

  NumberHelpers.number_to_percentage = function(float, opts) {
    var _delimiter, _precision, _separator, _significant, _strip_insignificant_zeros, ref, ref1, ref2, ref3, ref4;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 3;
    _separator = (ref1 = opts.separator) != null ? ref1 : '.';
    _significant = (ref2 = opts.significant) != null ? ref2 : false;
    _delimiter = (ref3 = opts.delimiter) != null ? ref3 : '';
    _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false;
    if (!isNaN(float)) {
      float = NumberHelpers.number_with_precision(float, {
        precision: _precision,
        significant: _significant,
        delimiter: _delimiter,
        separator: _separator,
        strip_insignificant_zeros: _strip_insignificant_zeros
      });
    }
    return float + "%";
  };

  NumberHelpers.reconstitute_exponential = function(num, exp) {
    var numlength, vals;
    if (num.indexOf('e') !== -1) {
      vals = num.split('e');
      num = vals[0];
      exp = vals[1];
    }
    exp = +exp;
    num += '';
    if (exp === 0) {
      return num;
    }
    num = num.replace(/\./, '');
    numlength = num.length;
    if (exp > 0) {
      if ((exp + 1) < numlength) {
        num = num.substr(0, exp + 1) + '.' + num.substr(exp + 1);
      } else {
        num = (num + this.ZEROS).substr(0, exp + 1);
      }
    } else {
      num = "0." + (this.ZEROS + num).substr(this.ZEROS.length + 1 + exp);
    }
    return num;
  };

  NumberHelpers.number_to_rounded = function(float, opts) {
    var _delimiter, _precision, _separator, _significant, _skip_empty_fractionals, _strip_insignificant_zeros, decimal, dlen, i, integer, newlen, num_array, number, ref, ref1, ref2, ref3, ref4, ref5, rounded, significant_val, value, zcount;
    if (opts == null) {
      opts = {};
    }
    _precision = (ref = opts.precision) != null ? ref : 3;
    _significant = (ref1 = opts.significant) != null ? ref1 : false;
    _delimiter = (ref2 = opts.delimiter) != null ? ref2 : '';
    _separator = (ref3 = opts.separator) != null ? ref3 : '.';
    _strip_insignificant_zeros = (ref4 = opts.strip_insignificant_zeros) != null ? ref4 : false;
    _skip_empty_fractionals = opts.strip_empty_fractional_parts;
    if (_significant && !_precision) {
      _significant = false;
    }
    rounded = this.safe_round(float, _significant, _precision);
    if (_significant && _precision) {
      value = rounded.toExponential().split('e');
      significant_val = (+value[0]).toFixed(_precision - 1);
      rounded = this.reconstitute_exponential(significant_val, value[1]);
    } else if (_precision) {
      rounded = rounded.toFixed(_precision);
    }
    rounded += '';
    number = rounded.split('.');
    integer = number[0];
    decimal = (ref5 = number[1]) != null ? ref5 : '';
    integer = NumberHelpers.number_with_delimiter(integer, {
      delimiter: _delimiter
    });
    if (_strip_insignificant_zeros) {
      dlen = decimal.length;
      newlen = dlen;
      while (newlen > 0 && decimal[newlen - 1] === '0') {
        newlen = newlen - 1;
      }
      if (newlen === 0) {
        decimal = '';
      } else if (newlen !== dlen) {
        decimal = decimal.slice(0, newlen);
      }
    }
    if (_skip_empty_fractionals) {
      i = 0;
      zcount = 0;
      num_array = decimal.split('');
      dlen = decimal.length;
      while (i < dlen) {
        if (num_array[i] === '0') {
          zcount++;
        }
        i++;
      }
      if (zcount === dlen) {
        decimal = '';
      }
    }
    if (!decimal) {
      _separator = '';
    }
    return "" + integer + _separator + decimal;
  };

  return NumberHelpers;

})();
