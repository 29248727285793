// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-target="nested-form.template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-target="nested-form.links">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#add_association" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#remove_association" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "addLink", "removeLink", "template", "original" ]

  connect() {
    this.element[this.identifier] = this
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.addLinkTarget.insertAdjacentHTML('beforebegin', content)

    const credentialFieldsets = this.element.querySelectorAll("[data-new-record]")
    const lastCredentialFieldset = credentialFieldsets[credentialFieldsets.length - 1]
    
    let focusableField = lastCredentialFieldset.querySelector("trix-editor,input,select")
    
    focusableField.focus()
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      let destroyField = wrapper.querySelector("input[name*='_destroy']")
      if (destroyField) { destroyField.value = 1 }
      wrapper.style.display = 'none'
    }
  }

  hide_original(event) {
    this.originalTarget.classList.add('hidden')
    this.addLinkTarget.classList.add('hidden')

    this.element.querySelector("input").focus()
  }

  show_original(event) {
    this.originalTarget.classList.remove('hidden')
    this.addLinkTarget.classList.remove('hidden')

    // sometimes wasn't working
    requestAnimationFrame(() => this.originalTarget.querySelector("trix-editor,input,select").focus())
  }
}
