import { Controller } from "stimulus"

const snakeCase = (string) => {
	return string.replace(/\d+/g, ' ')
		.split(/ |\B(?=[A-Z])/)
		.map((word) => word.toLowerCase())
		.join('_');
};

export default class ComboboxController extends Controller {
	static targets = [ "selected", "template", "label", "badge", "badgeCheckbox", "badgeName", "emptyBadge" ]
	
	connect() {
	}
	
	chooseAndReset(evt) {
		const value = evt.currentTarget.value
		const text = evt.currentTarget.selectedOptions[0].text
		
		this._cloneTemplate(value, text)
		this._removeOptionFromList(evt.currentTarget, evt.currentTarget.value)
	}
	_cloneTemplate(value, text) {
		const clone = this.templateTarget.content.cloneNode(true)
		const idVal = "review_recipients_" + snakeCase(value)

		clone.querySelector("[data-combobox-target='badge']").setAttribute('for', idVal)
		clone.querySelector("[data-combobox-target='badgeCheckbox']").setAttribute('id', idVal)
		clone.querySelector("[data-combobox-target='badgeCheckbox']").setAttribute('value', value)
		clone.querySelector("[data-combobox-target='badgeName']").textContent = text
		this.selectedTarget.append(clone)
		if (this.hasEmptyBadgeTarget) {
			this.emptyBadgeTarget.remove()
		}
	}
	_removeOptionFromList(optionsList, selectValue) {
		const selectedOption = Array.from(optionsList.children).find(option => option.value == selectValue)
		selectedOption.remove()
	}
}
