import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dynamic", "input" ]
  static values = {
    matcher: String
  }

  connect() {
    this.hiddenClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    if (this.hasInputTarget) {
      let matchingTargets = this.dynamicTargets.filter(target => {
        return target.dataset.visibilityMatcherValue == this.inputTarget.value
      })
      
      matchingTargets.forEach(target => {
        target.classList.toggle(this.hiddenClass)
      })
    } else {
      this.dynamicTargets.forEach(target => {
        target.classList.toggle(this.hiddenClass)
      }) 
    }
  }

  show(event) {
    event.preventDefault()
    
    if (this.hasInputTarget) {
      this.dynamicTargets.forEach( target => {
        target.classList.add(this.hiddenClass)
      })
      
      let matchingTargets = this.dynamicTargets.filter(target => {
        return target.dataset.visibilityMatcherValue == this.inputTarget.value
      })
      
      matchingTargets.forEach(target => {
        target.classList.remove(this.hiddenClass)
      })
    } else {
      this.dynamicTargets.forEach(target => {
        target.classList.remove(this.hiddenClass)
      }) 
    }
  }

  hide(event) {
    event.preventDefault()

    this.dynamicTargets.forEach( target => {
      target.classList.add(this.hiddenClass)
    })
  }
}
