import { Controller } from "stimulus"

export default class extends Controller {
  replaceText(event) {
    const elements = this.element.querySelectorAll("[data-disable-with]")
    const nonClickedElements = [...elements].filter(element => {
      return element !== event.currentTarget;
    });

    nonClickedElements.forEach(element => {
      element.removeAttribute('data-disable-with');
      element.setAttribute('disabled', true);
    })
  }
}
