import { Controller } from "stimulus"

function camelize(text) {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  })
}

export default class extends Controller {
  static targets = [ "label", "placeholder", "field", "hint", "select", "radio" ]
  static values = {
    type: String,
    matcher: String
  }

  connect() {
    if (this.hasSelectTarget) {
      const sourceType = this.selectTarget.options[this.selectTarget.selectedIndex].value
      this._updateLabels(sourceType)
      this._updatePlaceholders(sourceType)
      this._updateFields(sourceType)
      this._updateHints(sourceType)
    }
  }

  updateLabels(event) {
    const sourceTypeNode = event.target
    const sourceType = sourceTypeNode.options[sourceTypeNode.selectedIndex].value
    this._updateLabels(sourceType)
  }

  updatePlaceholders(event) {
    const sourceTypeNode = event.target
    const sourceType = sourceTypeNode.options[sourceTypeNode.selectedIndex].value
    this._updatePlaceholders(sourceType)
  }

  updateFields(event) {
    const sourceTypeNode = event.target
    const sourceType = sourceTypeNode.options[sourceTypeNode.selectedIndex].value
    this._updateFields(sourceType)
  }
  
  updateHints(event) {
      const sourceTypeNode = event.target
      const sourceType = sourceTypeNode.options[sourceTypeNode.selectedIndex].value
      this._updateHints(sourceType)
    }

  register(event) {
    setTimeout(function() {
      const url = event.target.value
      
      const matchingRadio = this.radioTargets.find(el => {
        if ('integrationsMatcherValue' in el.dataset) {
          if (url.match(el.dataset.integrationsMatcherValue)) {
            return true
          } else {
            return false
          }
        } else {
          if (url.match(/atlassian\.net/) && el.dataset.integrationsTypeValue == "jiraCloud") {
            return true
          }
          else if (url.match(/jira/) && el.dataset.integrationsTypeValue == "jiraServer") {
            return true
          }
          else if (url.match(/github.com/) && el.dataset.integrationsTypeValue == "githubProject") {
            return true
          }
          else if (url.match(/pivotaltracker.com/) && el.dataset.integrationsTypeValue == "pivotalTracker") {
            return true
          }
        }
      })

      if (matchingRadio) {
        matchingRadio.checked = true
      }
    }.bind(this), 0)
  }

  _updateLabels(sourceType) {
    this.labelTargets.forEach(target => {
      let labelForSourceType = target.dataset[camelize(sourceType)]
      if (labelForSourceType) {
        target.textContent = labelForSourceType
      }
    })
  }

  _updatePlaceholders(sourceType) {
    this.placeholderTargets.forEach(target => {
      let labelForSourceType = target.dataset[camelize(sourceType)]
      if (labelForSourceType) {
        target.placeholder = labelForSourceType
      }
    })
  }

  _updateFields(sourceType) {
    this.fieldTargets.forEach(target => {
      let visibilityForSourceType = target.dataset[camelize(sourceType)]
      if (visibilityForSourceType == "hide") {
        target.classList.add("hidden")
      } else {
        target.classList.remove("hidden")
      }
    })
  }
  
  _updateHints(sourceType) {
    console.log('_updateHints....', this.hintTargets, sourceType)
    this.hintTargets.forEach(target => {
      let visibilityForSourceType = target.dataset[camelize(sourceType)]
      if (visibilityForSourceType == "show") {
        target.classList.remove("hidden")
      } else {
        target.classList.add("hidden")
      }
    })
  }
}
